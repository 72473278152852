import React from 'react';
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ReactSVG } from "react-svg";

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/bg.jpg"
import bgmobile from "../images/bg-mobile-jade.jpg"

import LogoJade from '../images/logo-jade.svg'
import LogoLvmh from '../images/logo-lvmh.svg'
import BtnWebsite from '../images/btn-website.svg'
import BtnEmail from '../images/btn-email.svg'
import BtnTel from '../images/btn-tel.svg'
import Facebook from '../images/facebook.svg'
import Linkedin from '../images/linkedin.svg'
import Youtube from '../images/youtube.svg'
import Arrow from '../images/arrow.svg'
import DownloadContact from '../images/download-contact.svg'

const NotFoundPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  var contacts = [
    {
      icon: BtnEmail,
      data: "mailto:marta.claudio@jadegroupe.com"
    },
    {
      icon: BtnTel,
      data: "tel:+351915175007"
    },
    {
      icon: DownloadContact,
      data: "https://marta-claudio.jadegroupe.pt/vcard.vcf"
    }
  ]

  var socialNetworks = [
    {
      link: "https://www.facebook.com/jadegroupeofficial",
      alt: "Facebook",
      img: Facebook
    },
    {
      link: "https://www.linkedin.com/company/jade-groupe/",
      alt: "LinkedIn",
      img: Linkedin
    },
    {
      link: "https://www.youtube.com/@jadegroupe9673",
      alt: "Youtube",
      img: Youtube
    }
  ]

  const shareContact = () => {
    if (typeof document !== 'undefined' && document !== 'undefined')
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      alert('Windows')
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      alert('Android')
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      alert('iOS')
      // Get the contact information from the website
      var contact = {
        name: "Marta Cláudio",
        phone: "+351 915 175 007",
        email: "marta.claudio@jadegroupe.com"
      };
      // create a vcard file
      var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";
      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);

      const newLink = document.createElement('a');
      newLink.download = contact.name + ".vcf";
      newLink.textContent = contact.name;
      newLink.href = url;

      newLink.click();
      return "iOS";
    }
  }



  return (
    <Layout>
      <Seo title="Marta Cláudio" />
      <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>
        {breakpoints.md ? (
          <>
            <div className='container'>
              <div className='left-div-mobile'>

                <ReactSVG src={LogoJade} className='jade-logo' />

                <ReactSVG src={LogoLvmh} className='lvmh-logo' />

                <a href="https://jadegroupe.pt/" className='btn-website' style={{ textDecoration: 'none' }}>
                  <ReactSVG src={BtnWebsite} />
                </a>


                <div className="div-social-mobile">

                  {socialNetworks.map((social, l) => (
                    <a href={social.link} target="_blank" key={"social" + l}>
                      <ReactSVG src={social.img} />
                    </a>
                  ))}
                </div>
              </div>


              <div className="div-person-mobile">
                <div>
                  <p className="name font-notosansdisplay">
                    {data.globalJson.info.name}
                  </p>
                  <p className="info font-generalsans">
                    {data.globalJson.info.department}
                  </p>
                </div>
                <div className='contact-icons'>
                  {contacts.map((contact, i) => (
                    <a href={contact.data} target="_blank" key={"contact" + i}>
                      <ReactSVG src={contact.icon} />
                    </a>
                  ))}
                </div>

                <div className='div-address-container'>
                  <div className="div-address-mobile">
                    {data.globalJson.address.map((address, l) => (
                      <a href={address.link} className="links-address" key={"address" + l} style={{ color: 'white', textTransform: 'uppercase', textDecoration: 'none' }}>
                        <p className='font-generalsans btn-address-mobile' dangerouslySetInnerHTML={{ __html: address.locationMobile }}></p>
                        <ReactSVG src={Arrow} />
                      </a>
                    ))}
                  </div>
                  <div className="div-address">
                    {data.globalJson.address.map((address, l) => (
                      <a href={address.link} key={"address" + l} style={{ color: 'white', marginTop: '50', textTransform: 'uppercase', textDecoration: 'none' }}>

                        <p className='font-generalsans' dangerouslySetInnerHTML={{ __html: address.location }} style={{ textAlign: 'left' }}></p>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>)
          :
          (
            <>
              <div className='container'>
                <div className='left-div'>
                  <div className="div-social">

                    <a href="https://jadegroupe.pt/" className='btn-website'>
                      <ReactSVG src={BtnWebsite} />
                    </a>

                    {socialNetworks.map((social, l) => (
                      <a href={social.link} target="_blank" key={"social" + l}>
                        <ReactSVG src={social.img} />
                      </a>
                    ))}


                  </div>

                  {/*DIV MORADA*/}
                  <div className="div-address">
                    {data.globalJson.address.map((address, l) => (
                      <a href={address.link} key={"address" + l} style={{ color: 'white', marginTop: '50', textTransform: 'uppercase', textDecoration: 'none' }}>
                        <span className="links-address">
                          <p className='font-generalsans btn-address-mobile' dangerouslySetInnerHTML={{ __html: address.locationMobile }}></p>
                          <ReactSVG src={Arrow} />
                        </span>
                        <p className='font-generalsans' dangerouslySetInnerHTML={{ __html: address.location }}></p>
                      </a>
                    ))}
                  </div>
                </div>

                <div className="div-person">
                  <StaticImage
                    src="../images/linha-superior.png"
                    loading="eager"
                    className='linha-superior'
                    quality={95}
                    formats={["auto"]}
                    alt=""
                    style={{}}
                  />

                  <div className='logos'>
                    <ReactSVG src={LogoJade} className='jade-logo' />

                    <ReactSVG src={LogoLvmh} className='lvmh-logo' />
                  </div>

                  <div>
                    <p className="name font-notosansdisplay">
                      {data.globalJson.info.name}
                    </p>
                    <p className="info font-generalsans">
                      {data.globalJson.info.department}
                    </p>
                  </div>

                  <div className='contact-icons'>
                    {contacts.map((contact, i) => (
                      (contact.icon !== DownloadContact ?
                        <a href={contact.data} target="_blank" key={"contact" + i}>
                          <ReactSVG src={contact.icon} />
                        </a>
                        :
                        null)
                    ))}
                  </div>

                  <StaticImage
                    src="../images/linha-inferior.png"
                    loading="eager"
                    className='linha-inferior'
                    quality={95}
                    formats={["auto"]}
                    alt=""
                    style={{}}
                  />
                </div>
              </div>
            </>
          )
        }
      </div >
    </Layout >
  )
}

export default NotFoundPage


export const IndexQuery = graphql`
  query notfoundJson {
    globalJson {
      info {
        name
        department
      }
      social {
        link
        alt
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      address {
        locationMobile
        location
        link
      }
    }
  }
`